<template>
    <div class="mx-5"> 
        <v-toolbar color="primary" dense extended dark>
            <v-toolbar-title>Catálogos del sistema</v-toolbar-title>
            <v-spacer/>
            <v-btn icon @click="$router.push({path: '/admin'})">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <template v-slot:extension>
                <v-btn text small @click='componente="pad-insabi"'>
                    Pad. INSABI
                </v-btn>
            </template>
        </v-toolbar>
        <v-sheet elevation="2" class="pt-2 pb-3">
            <component
                :is="componente"
            />
        </v-sheet>


    </div>
</template>

<script>
export default {
    components:{
        'pad-insabi':()=>import('@/views/admin/catalogos/PadInsabi'),
    },
    data:()=>({
        componente:'pad-insabi'
    })

}
</script>

<style>

</style>